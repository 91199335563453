import { keyframes } from '@emotion/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const setCarouselStyles = (
  isDraggable,
  shouldPause,
  autoplay,
  slideCount,
  slidesToShowDesktop,
  slidesToShowTablet,
  arrows,
  showBackground,
  alignment,
) => {
  const showBoxShadow = !showBackground;
  const expand = keyframes`
    from {
      transform: translate(-100%);
    }
    to {
      transform: translate(0);
    }
  `;

  return {
    backgroundColor: showBackground ? '#f1f0f0' : undefined,
    paddingY: showBackground ? ['30px', null, '50px'] : undefined,
    paddingX: showBackground ? ['30px', null, '150px'] : undefined,
    '.slick-slider': {
      // Magic number to not hide top box-shaddow
      paddingTop: showBoxShadow ? '15px' : '0px',
      // Magic number to not hide bottom of box-shaddow if there is no controls underneath
      paddingBottom: [
        slideCount === 1 ? '15px' : 0,
        slideCount <= slidesToShowTablet ? '15px' : 0,
        null,
        slideCount <= slidesToShowDesktop ? '15px' : 0,
      ],
      userSelect: isDraggable ? 'none' : 'text',
      ...(arrows
        ? {}
        : {
            overflowX: 'hidden',
            // Make margin wider to handle columns on .slick-slide
            mx: [`calc(-32px / 2)`],
          }),
    },
    '.slick-list': {
      ...(arrows
        ? {
            boxShadow: showBoxShadow ? '0px 2px 4px 0px rgba(0, 0, 0, 0.2)' : undefined,
            borderRadius: '16px',
          }
        : {
            overflow: 'visible',
          }),
      '.slick-track': {
        // Force slides to have same height: https://stackoverflow.com/a/53131996
        display: 'flex',
        '.slick-slide': {
          // Fake 32px columns: https://github.com/kenwheeler/slick/issues/582#issuecomment-61882540
          ...(arrows
            ? {}
            : {
                px: [`calc(32px / 2)`],
              }),
          height: 'inherit',
          '&>*': {
            height: '100%',
          },
          '@media (pointer: fine)': {
            // Only buttons & anchors currently supported in carousel, if other tab elements supported expand this selector
            '&[aria-hidden=true] > * a, &[aria-hidden=true] > * button': {
              visibility: 'hidden',
              transition: 'visibility 0.5s linear',
            },
          },
          display: alignment === 'CENTER' ? 'flex' : undefined,
          justifyContent: alignment === 'CENTER' ? 'center' : undefined,
        },
      },
    },
    '.slick-prev': {
      '&::before': {
        content: 'none',
      },
    },
    '.slick-next': {
      '&::before': {
        content: 'none',
      },
    },
    '.slick-dots': {
      position: 'relative',
      bottom: 0,
      height: ['48px', '54px', null, '60px'],
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'flex-end',
      // Negative margin and width calc() accounts for the mx between li
      mx: ['-4px', '-8px'],
      width: ['calc(100% + 8px)', 'calc(100% + 16px)'],
      px: [`calc(32px / 2)`],
      li: {
        width: '100px',
        maxWidth: '100px',
        // Height on li/button and mt on ::before is to create an accessible click area while visually displaying a 6px tall bar
        height: ['48px', null, null, '6px'],
        mx: ['4px', '8px'],
        button: {
          position: 'relative',
          width: '100%',
          height: ['48px', null, null, '6px'],
          borderRadius: '3px',
          overflow: 'hidden',
          p: 0,
          // ::before and ::after pseudo-elements necessary for progress bar styling
          '&::before': {
            opacity: 1,
            content: "''",
            background: 'lightGrey',
            height: '6px',
            width: '100%',
            borderRadius: '3px',
            mt: ['42px', null, null, '0px'],
          },
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 1,
            content: "''",
            background: '#000',
            height: '6px',
            width: '100%',
            borderRadius: '3px',
            mt: ['42px', null, null, '0px'],
            transformOrigin: '0%',
          },
        },
        '&.slick-active': {
          'button::after': {
            // Magic number for durationis autoplaySpeed + speed in CarouselSlider settings
            animation: [null, null, null, autoplay && `${expand} 8000ms linear`],
            animationPlayState: [null, null, null, shouldPause && 'paused'],
            transition: '.2s background',
          },
        },
        '&.slick-active ~ li': {
          'button:not(:hover)::after': {
            background: 'transparent',
          },
          'button:focus-visible::before': {
            transition: '.2s background',
            background: '#185AD2',
          },
        },
        'button:hover::after': {
          transition: '.2s background',
          background: '#185AD2',
        },
        'button:focus-visible::after': {
          transition: '.2s background',
          background: '#185AD2',
        },
      },
    },
  };
};
