/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CarouselSlideProps } from '../types';
import { ITEM_TYPE_VS_RENDERER } from '../../grid/constants';
import TemplateRenderer from '../../../components/tempateRenderer/TemplateRenderer';
import { Review, SessionCard } from '@sprinklr/shared-lib';

const CarouselSlide: React.FC<CarouselSlideProps> = props => {
  const { slide } = props;

  const content = slide?.slide;
  const contentType = content?.__typename;

  const renderSlide = () => {
    switch (contentType) {
      case 'ContentfulContentBlock': {
        // Content Block must have the type passed to it from a parent component in order to properly render.
        const contentBlockType = slide?.contentBlockType || 'CONTENT_BLOCK'; // this can be deprecate
        const Component = ITEM_TYPE_VS_RENDERER[contentBlockType];
        // Refactor to switch statemnt if custom styles are needed for other content block types
        const contentBlockStyles =
          contentBlockType === 'MEDIA_CARD'
            ? { height: '100%', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }
            : null;
        return (
          <Component item={content} {...content} cardSx={contentBlockStyles} />
        );
      }
      case 'ContentfulReview': {
        return <Review review={content} />;
      }
      case 'ContentfulTemplateContentColumns': {
        const Component = ITEM_TYPE_VS_RENDERER['CONTENT_COLUMNS'];
        return (
          <Component
            item={content}
            {...content}
            padding={{
              top: content?.topMargin,
              bottom: content?.bottomMargin,
            }}
          />
        );
      }
      case 'ContentfulSessionCard': {
        return <SessionCard {...content} />;
      }
      default: {
        return <TemplateRenderer templates={[content]} isSlideContent />;
      }
    }
  };

  return renderSlide();
};

export default CarouselSlide;
