/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, CardGrid } from '@sprinklr/shared-lib';
import { PageCardDisplayTemplateProps } from './types';
import PageCardWrapper from './PageCardWrapper';
import CarouselTemplate from '@sprinklr/website/src/templates/carouselTemplate';

const PageCardDisplayTemplate: React.FC<
  PageCardDisplayTemplateProps
> = props => {
  const { cardDisplayType, pageCardReferences } = props;

  let hasContentfulPageEntry = false;
  pageCardReferences?.map(ref => {
    if (ref?.__typename === 'ContentfulPage') hasContentfulPageEntry = true;
  });

  let variant = null;
  if (cardDisplayType === 'HORIZONTAL_CLEAN_TWO_COL' && !hasContentfulPageEntry)
    variant = 'horizontalWide';
  if (
    cardDisplayType === 'HORIZONTAL_SHADOW_THREE_COL' ||
    hasContentfulPageEntry
  )
    variant = 'vertical';
  if (
    cardDisplayType === 'VERTICAL_SHADOW_THREE_COL' &&
    !hasContentfulPageEntry
  )
    variant = 'vertical';

  const shouldBeCarousel =
    cardDisplayType === 'VERTICAL_SHADOW_THREE_COL' &&
    pageCardReferences?.length > 3;

  const pageCards = pageCardReferences?.map((card, i) => {
    return (
      <PageCardWrapper
        hasContentfulPageEntry={hasContentfulPageEntry}
        cardDisplayType={cardDisplayType}
        {...card}
        key={card.slug + i}
      />
    );
  });

  return (
    <Container>
      {!shouldBeCarousel && (
        <CardGrid cardVariant={variant} gridSx={{ rowGap: ['40px', 4, 5] }}>
          {pageCards}
        </CardGrid>
      )}
      {shouldBeCarousel && (
        <CarouselTemplate
          topMargin="NONE"
          slidesToShowDesktop={3}
          slidesToShowTablet={2}
          noContainerLeftRightPadding={true}
          autoplay={true}
        >
          {pageCards}
        </CarouselTemplate>
      )}
    </Container>
  );
};

export default PageCardDisplayTemplate;
