/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PageCard from './PageCard';
import { PageCardWrapperProps } from './types';
import isWebinarExpired from '@sprinklr/website/exploreSrc/utils/isWebinarExpired';

const PageCardWrapper: React.FC<PageCardWrapperProps> = props => {
  const {
    blogAuthors,
    blogCardDescription,
    blogPostImage,
    blogPostTitle,
    cardDisplayType,
    hasContentfulPageEntry,
    publishDate,
    slug,
    productCategory,
    navSlug,
    categorySlug,
    pageCardTitle,
    pageCardDescription,
    cardDescription,
    resourceTitle,
    resourceImage,
    eventCardImage,
    eventDate,
    eventTitle,
    nodeLocale,
    __typename,
  } = props;

  let variant = 'vertical';
  if (cardDisplayType === 'HORIZONTAL_CLEAN_TWO_COL' && !hasContentfulPageEntry)
    variant = 'horizontalClean';
  if (
    cardDisplayType === 'HORIZONTAL_SHADOW_THREE_COL' ||
    hasContentfulPageEntry
  )
    variant = 'horizontalShadow';
  if (
    cardDisplayType === 'VERTICAL_SHADOW_THREE_COL' &&
    !hasContentfulPageEntry
  )
    variant = 'verticalShadow';

  let pageReferenceType = null;
  let ctaLabel = null;
  let slugPrefix = null;
  let pageCategory = null;
  const pageSlug = slug || navSlug;

  if (__typename === 'ContentfulBlogPostPage') {
    pageReferenceType = 'Blog';
    ctaLabel = 'Read Article';
    slugPrefix = 'blog';
    pageCategory = 'Blog';
  }

  if (__typename === 'ContentfulEvent') {
    const isOnDemand = isWebinarExpired(eventDate);
    pageReferenceType = isOnDemand ? 'On-Demand Webinar' : 'Upcoming Webinar';
    ctaLabel = isOnDemand ? 'Watch Now' : 'Learn More';
    slugPrefix = 'events';
    pageCategory = isOnDemand ? 'On-Demand Webinar' : 'Upcoming Webinar';
  }

  if (__typename === 'ContentfulResource') {
    pageReferenceType = 'Resource';
    ctaLabel = 'Learn More';
    slugPrefix = 'resources';
    pageCategory = 'Resource';
  }

  if (__typename === 'ContentfulPage') {
    pageReferenceType = 'Page';
    ctaLabel = 'Learn More';
    slugPrefix = categorySlug;

    const primaryProdCategory = productCategory && productCategory[0];

    if (
      primaryProdCategory === 'Care' ||
      primaryProdCategory === 'Engagement' ||
      primaryProdCategory === 'Marketing & Advertising' ||
      primaryProdCategory === 'Research'
    )
      pageCategory = `Modern ${primaryProdCategory}`;
    if (primaryProdCategory === 'Platform') pageCategory = primaryProdCategory;
    if (primaryProdCategory === 'No Product Category' || !primaryProdCategory)
      pageCategory = null;
  }

  const pageCardProps = {
    slug: pageSlug,
    slugPrefix: slugPrefix,
    cardTitle: blogPostTitle || pageCardTitle || eventTitle || resourceTitle,
    cardDescription:
      blogCardDescription || pageCardDescription || cardDescription,
    cardImage: blogPostImage || resourceImage || eventCardImage,
    publishDate: publishDate,
    blogAuthors: blogAuthors,
    node_locale: nodeLocale,
    pageReferenceType: pageReferenceType,
    category: pageCategory,
    ctaLabel: ctaLabel,
    variant: variant,
  };

  return (
    <Box>
      <PageCard {...pageCardProps} />
    </Box>
  );
};

export default PageCardWrapper;
