/** @jsx jsx */
import { useRef, useCallback, useState, useContext } from 'react';
import PageContext from '@sprinklr/shared-lib/context/pageContext';
import { Box, jsx } from 'theme-ui';
import { Container, RichText } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { CarouselTemplateProps } from './types';
import { CarouselSlider } from '@sprinklr/shared-lib/components/carouselSlider';
import CarouselSlide from './components/CarouselSlide';
import { setCarouselStyles } from './styles';
import { Title } from '@sprinklr/shared-lib';

import { SIZE_VARIANTS } from './sizeVariants';

export const getMaxWidth = sizeVariant => {
  switch (sizeVariant) {
    case SIZE_VARIANTS.mw1320: {
      return 1320;
    }

    case SIZE_VARIANTS.mw1180: {
      return 1180;
    }

    default:
      return undefined;
  }
};

const CarouselTemplate: React.FC<CarouselTemplateProps> = props => {
  const {
    autoplay,
    bottomMargin,
    topMargin,
    slidesToShowDesktop,
    slidesToShowTablet,
    noContainerLeftRightPadding,
    carouselSlides,
    isDraggable = false,
    children,
    arrows,
    sizeVariant,
    showDots,
    slidesToScroll,
    slidesToShowMobile,
    arrowVariant,
    showBackground,
    alignment,
    title,
  } = props;

  const { isModalMovieOpen } = useContext(PageContext);

  const [pt, pb] = useContainerPadding({
    top: topMargin,
    bottom: bottomMargin,
  });

  const [shouldPause, setShouldPause] = useState(false);
  const timeoutRef = useRef();

  const handleMouseEnter = () => !isModalMovieOpen && setShouldPause(true);
  const handleMouseLeave = () => !isModalMovieOpen && setShouldPause(false);

  const handleBlur = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setShouldPause(false);
    }) as any;
  }, []);

  const handleFocus = useCallback(() => {
    setShouldPause(true);
    clearTimeout(timeoutRef.current);
  }, []);

  const renderSlides = useCallback(() => {
    if (carouselSlides)
      return carouselSlides.map((slide, i) => (
        <CarouselSlide key={i} slide={slide} />
      ));
    if (children) return children;
    return;
  }, [carouselSlides, children]);

  const slideCount = carouselSlides?.length;

  return (
    <Container
      fullWidth={noContainerLeftRightPadding}
      containerSx={{ pt, pb }}
      maxWidth={getMaxWidth(sizeVariant)}
    >
      {title ? (
        <Box sx={{ marginBottom: ['4px', '16px', '56px'] }}>
          <Title title={title} />
        </Box>
      ) : null}
      <Box
        sx={setCarouselStyles(
          isDraggable,
          shouldPause,
          autoplay,
          slideCount,
          slidesToShowDesktop,
          slidesToShowTablet,
          arrows,
          showBackground,
          alignment,
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <CarouselSlider
          arrows={arrows}
          autoplay={autoplay}
          slidesToShowDesktop={slidesToShowDesktop}
          slidesToShowTablet={slidesToShowTablet}
          draggable={isDraggable}
          shouldPause={shouldPause}
          showDots={showDots}
          slidesToScroll={slidesToScroll}
          slidesToShowMobile={slidesToShowMobile}
          arrowVariant={arrowVariant}
        >
          {renderSlides()}
        </CarouselSlider>
      </Box>
    </Container>
  );
};

export default CarouselTemplate;
